import styled from "styled-components";

export const WorkPageContainer = styled.section`
  padding-top: 160px;
  .mantine-List-itemWrapper {
    font-weight: 600;
  }
`;

export const WorkMotionContainer = styled.div``;
